import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import SEO from "../../components/SEO"

const Collections = ({ data }) => {
  const collections = data.allCollectionsPt.edges.sort(
    (a, b) => -a.node.createdAt.localeCompare(b.node.createdAt)
  )

  return (
    <Layout>
      <SEO
        title="Coleções"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/collections`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/collections`}
      />

      <div className="collections">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Coleções"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="collections-header">
            <h1>Explore as mais variadas coleções</h1>
            <p>
              Nizam funciona como uma biblioteca pessoal, uma fonte de
              conhecimento e desenvolvimento pessoal.
            </p>
          </div>

          <div className="collection-list">
            {collections.map(collection => (
              <Link
                to={`/pt/collections/${collection.node.slug}`}
                className="collection-item"
                style={{ display: "grid" }}
              >
                <div
                  className="collection-placer"
                  style={{
                    backgroundImage: `url(${collection.node.imageUrl})`,
                    backgroundSize: "cover",
                    borderRadius: "10px",
                  }}
                ></div>

                <div className="collection-info">
                  <h3>{collection.node.name}</h3>
                  <span>{collection.node.cards.length} leituras</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Collections

export const query = graphql`
  query {
    allCollectionsPt {
      edges {
        node {
          name
          slug
          imageUrl
          createdAt
          cards {
            name
            slug
            type
            topic {
              name
              color
            }
            meta {
              content
              excerpt
              listFields {
                title
                content
              }
            }
          }
        }
      }
    }
  }
`
